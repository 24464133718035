import React from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { InternalLink } from '@/atoms/InternalLink'
import { slugs } from '@/constants/slugs'
import { MediaThumbnail } from '@/molecules/MediaCard/MediaThumbnail'
import { Media } from '@/utils/EpisodeUtil'
import { MediaDescriptors } from './MediaDescriptors'

export interface MediaCardProps extends Media {
  onCalendarClick?: () => void
  onClick?: () => void
  userHasEarlyAccess?: boolean
  hasWatchableReasons?: boolean
}

const earlyAccessHoursBeforeConfig = {
  [slugs.theChosen]: 24,
  [slugs.wingfeatherSaga]: 48,
  [slugs.tuttleTwins]: 96,
}

interface UseMediaDatesArgs {
  earlyAccessDate?: string
  publiclyAvailableDate?: string
  unavailableReason?: string
  userHasEarlyAccess?: boolean
}

interface MediaDatesReturn {
  canStreamEarlyAccess: boolean
  canStreamEpisode: boolean
  canStreamFreeEpisode: boolean
  earlyAccessDateJS?: Date
  earlyAccessDateTime?: DateTime
  publicAvailableDateJS?: Date
  userHasEarlyAccess?: boolean
}

const useMediaDates = ({
  earlyAccessDate,
  publiclyAvailableDate,
  userHasEarlyAccess,
}: UseMediaDatesArgs): MediaDatesReturn => {
  const now = new Date()

  const publicAvailableDateJS = publiclyAvailableDate ? new Date(publiclyAvailableDate) : undefined
  const earlyAccessDateJS = earlyAccessDate ? new Date(earlyAccessDate) : undefined

  const earlyAccessDateTime = earlyAccessDateJS ? DateTime.fromJSDate(earlyAccessDateJS) : undefined

  const canStreamFreeEpisode = !!publicAvailableDateJS && publicAvailableDateJS <= now
  const canStreamEarlyAccess = !!earlyAccessDateJS && earlyAccessDateJS <= now && !canStreamFreeEpisode
  const canStreamEpisode = true

  return {
    publicAvailableDateJS,
    earlyAccessDateJS,
    earlyAccessDateTime,
    canStreamFreeEpisode,
    canStreamEarlyAccess,
    canStreamEpisode,
    userHasEarlyAccess,
  }
}

export const MediaCard: React.FC<MediaCardProps> = ({
  activeDate,
  aspectClassName = 'aspect-w-16 aspect-h-9',
  bottomBackgroundGradientClass,
  className = '',
  description,
  displayDescription = true,
  earlyAccessDate,
  episodeSlug,
  firstEarlyAccessEpisode = false,
  hasEarlyAccess = false,
  href,
  imgPriority,
  includeHoverScale = true,
  objectPositionClassName = 'object-left',
  onClick,
  poster,
  posterTransformation = 'c_fill,q_auto,ar_16:9,g_north,w_480,f_webp',
  prerelease = false,
  progress,
  projectSlug,
  projectType,
  publiclyAvailableDate,
  subtitle,
  title,
  unavailableReason,
  userHasEarlyAccess,
  guid,
  hasWatchableReasons = false,
}) => {
  const { canStreamEarlyAccess, canStreamEpisode, canStreamFreeEpisode, earlyAccessDateJS, publicAvailableDateJS } =
    useMediaDates({
      earlyAccessDate,
      publiclyAvailableDate,
      unavailableReason,
      userHasEarlyAccess,
    })

  if (!poster) return null

  const earlyAccessHoursBefore = earlyAccessHoursBeforeConfig[projectSlug] ?? 0

  return (
    <div
      aria-label={href.includes('/community/stories') ? 'video-story' : 'episode'}
      className={classNames(['text-white relative group', className])}
    >
      <div className={classNames('relative', { 'cursor-pointer': canStreamEpisode })}>
        {canStreamEpisode ? (
          <InternalLink
            href={href}
            className="cursor-pointer"
            aria-label={href.includes('/community/stories') ? 'video-story-link' : 'episode-link'}
            onClick={onClick}
          >
            <MediaThumbnail
              activeDate={activeDate}
              aspectClassName={aspectClassName}
              bottomBackgroundGradientClass={bottomBackgroundGradientClass}
              canStreamEarlyAccess={canStreamEarlyAccess}
              canStreamEpisode={canStreamEpisode}
              canStreamFreeEpisode={canStreamFreeEpisode}
              earlyAccessDate={earlyAccessDateJS}
              earlyAccessHoursBefore={earlyAccessHoursBefore}
              episodeSlug={episodeSlug}
              firstEarlyAccessEpisode={firstEarlyAccessEpisode}
              hasEarlyAccess={hasEarlyAccess}
              href={href}
              imgPriority={imgPriority}
              includeHoverScale={includeHoverScale}
              objectPositionClassName={objectPositionClassName}
              poster={poster}
              posterTransformation={posterTransformation}
              prerelease={prerelease}
              progress={progress}
              projectSlug={projectSlug}
              projectType={projectType}
              publiclyAvailableDate={publicAvailableDateJS}
              title={title}
              unavailableReason={unavailableReason}
              userHasEarlyAccess={userHasEarlyAccess}
              hasWatchableReasons={hasWatchableReasons}
              guid={guid}
            />
          </InternalLink>
        ) : (
          <MediaThumbnail
            activeDate={activeDate}
            aspectClassName={aspectClassName}
            bottomBackgroundGradientClass={bottomBackgroundGradientClass}
            canStreamEarlyAccess={canStreamEarlyAccess}
            canStreamFreeEpisode={canStreamFreeEpisode}
            earlyAccessDate={earlyAccessDateJS}
            earlyAccessHoursBefore={earlyAccessHoursBefore}
            episodeSlug={episodeSlug}
            firstEarlyAccessEpisode={firstEarlyAccessEpisode}
            hasEarlyAccess={hasEarlyAccess}
            href={href}
            imgPriority={imgPriority}
            includeHoverScale={includeHoverScale}
            objectPositionClassName={objectPositionClassName}
            poster={poster}
            posterTransformation={posterTransformation}
            prerelease={prerelease}
            progress={progress}
            projectSlug={projectSlug}
            projectType={projectType}
            publiclyAvailableDate={publicAvailableDateJS}
            title={title}
            unavailableReason={unavailableReason}
            userHasEarlyAccess={userHasEarlyAccess}
            hasWatchableReasons={hasWatchableReasons}
            guid={guid}
          />
        )}
      </div>
      <MediaDescriptors
        title={title}
        subtitle={subtitle}
        displayDescription={displayDescription}
        description={description}
        href={href}
      />
    </div>
  )
}
