import React from 'react'
import { CaptionXS } from '@/atoms/Text'
import { useLocale, Locale } from '@/utils/LocaleUtil'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  earlyAccessDate?: Date
  isGuildMember: boolean
  unavailableReason?: string | null
  publiclyAvailableDate?: Date
}

export const GuildAccessPill: React.FC<Props> = ({
  isGuildMember,
  earlyAccessDate,
  unavailableReason,
  publiclyAvailableDate,
}) => {
  const { t } = useTranslate('watch')
  const { locale } = useLocale()

  const text = getPillText({
    isGuildMember: isGuildMember,
    guildEarlyAccessDate: earlyAccessDate ? new Date(earlyAccessDate) : null,
    unavailableReason: unavailableReason ?? undefined,
    publicAccessDate: publiclyAvailableDate ? new Date(publiclyAvailableDate) : null,
    t: t,
    locale,
  })

  return (
    <div className="absolute bottom-3 right-2 z-10 p-1">
      {text && (
        <CaptionXS className="whitespace-nowrap rounded-full bg-core-gray-900/90 px-3 py-1 text-right uppercase backdrop-blur-lg">
          {text}
        </CaptionXS>
      )}
    </div>
  )
}

type GetPillTextParams = {
  isGuildMember: boolean
  guildEarlyAccessDate: Date | null
  unavailableReason?: string
  t: TranslateFunction
  locale: Locale
  publicAccessDate: Date | null
}

function areDatesEqual(date1?: Date, date2?: Date) {
  if (!date1 || !date2) return false
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

function getPillText({
  isGuildMember,
  guildEarlyAccessDate,
  unavailableReason,
  t,
  locale,
  publicAccessDate,
}: GetPillTextParams) {
  const now = Date.now()
  const fortnightAgo = new Date(Date.now() - 12096e5)

  if (unavailableReason && !guildEarlyAccessDate && !publicAccessDate) {
    return t('comingSoon', 'Coming Soon')
  } else if (!guildEarlyAccessDate && !publicAccessDate) {
    return null
  }

  const isReleaseToday = guildEarlyAccessDate ? areDatesEqual(guildEarlyAccessDate, new Date()) : false

  if (isReleaseToday) {
    return handleReleaseToday({ isReleaseToday, unavailableReason, guildEarlyAccessDate, t, locale, fortnightAgo })
  }

  if (unavailableReason && guildEarlyAccessDate && guildEarlyAccessDate.getTime() > now) {
    return Intl.DateTimeFormat(locale, { dateStyle: 'medium' }).format(guildEarlyAccessDate)
  } else if (unavailableReason && publicAccessDate && publicAccessDate.getTime() > now) {
    return Intl.DateTimeFormat(locale, { dateStyle: 'medium' }).format(publicAccessDate)
  } else if (
    guildEarlyAccessDate &&
    (unavailableReason || isGuildMember) &&
    guildEarlyAccessDate.getTime() > fortnightAgo.getTime()
  ) {
    return t('new', 'New')
  } else if (unavailableReason) {
    return null
  }

  return null
}

type HandleReleaseTodayParams = {
  isReleaseToday: boolean
  guildEarlyAccessDate: Date | null
  unavailableReason?: string
  t: TranslateFunction
  locale: Locale
  fortnightAgo: Date
}

function handleReleaseToday({
  isReleaseToday,
  unavailableReason,
  guildEarlyAccessDate,
  t,
  locale,
  fortnightAgo,
}: HandleReleaseTodayParams) {
  if (isReleaseToday) {
    if (unavailableReason && guildEarlyAccessDate && guildEarlyAccessDate.getTime() > Date.now()) {
      return t('todayAtTime', 'Today at {{time}}', {
        time: Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(guildEarlyAccessDate),
      })
    } else if (unavailableReason && guildEarlyAccessDate && guildEarlyAccessDate.getTime() > fortnightAgo.getTime()) {
      return t('new', 'New')
    } else if (unavailableReason) {
      return null
    }
  }
  return null
}
